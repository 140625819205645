import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import projImg from "../assets/img/project-img.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";

export const Projects = () => {

  const projects = [
    {
      title: "Konzolová aplikace v Javě",
      description: <button><a className="link" href="https://github.com/Dvidy/projektKeZkousce.git" target="_blank">GITHUB <ArrowRightCircle size={16}></ArrowRightCircle></a></button>,
      imgUrl: projImg,
    },
    {
      title: "Osobní portfolio",
      description: "Bootstrap & Javascript",
      imgUrl: projImg2,
    },
    {
      title: "REST API v Javě + Springboot",
      description: <button><a className="link" href="https://github.com/Dvidy/SpringPhonebook" target="_blank">GITHUB <ArrowRightCircle size={16}></ArrowRightCircle></a></button>,
      imgUrl: projImg3,
    },


  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
                <h2>Portfolio</h2>
                <p><br></br> S IT teprve začínám, avšak mile rád pro Vás vytvořím web, webovou aplikaci nebo design pro váš web. Jsem otevřený jakékoliv spolupráci. </p>
                <Tab.Container id="#" defaultActiveKey="first">
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}