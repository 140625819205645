import Bootstrap from "react-bootstrap";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer class="footer text-center">
    <div class="container">
        <div class="row">
            
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h4 class="mb-4">BYDLIŠTĚ</h4>
                <p class="pre-wrap lead mb-0">Česká Republika, Svitavy, 568 02</p>
            </div>
            <div class="col-lg-4 mb-5 mb-lg-0">
                <h4 class="mb-4">KDE MĚ NAJDEŠ</h4>
                <div className="social-icon">
                  <a href="www.linkedin.com/in/david-maruš-4355522b6" target="_blank"><img src={navIcon1} alt="linkedin logo" /></a>
                  <a href="https://www.facebook.com/profile.php?id=100007042872326" target="_blank"><img src={navIcon2} alt="facebook icon" /></a>
                  <a href="https://github.com/Dvidy" target="_blank"><img src={navIcon3} alt="github icon" /></a>
                </div>
            </div>
            <div class="col-lg-4">
                <h4 class="mb-4">KONTAKT</h4>
                <p class="pre-wrap lead mb-0"></p>
                <p>davidmarus18@gmail.com</p>
                <p>(+420) 732 149 054</p>
            </div>
        </div>
    </div>
    <section class="copyright py- text-center text-white">
            <small class="pre-wrap">Copyright © David Maruš 2024</small>
    </section>
</footer>
  )
}